import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IMemberInfo,
  IMrChatClaimsPackage,
  IMrChatPackage,
  IMrChatLpTag,
  ISegmentationData,
  IMobileHoops,
} from 'scripts/api/live-person/live-person.interfaces';
import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { selectConfig, selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { selectLivePersonSegmentationInfo } from 'scripts/selectors/live-person-selectors';
import { selectBenefits } from 'scripts/selectors/plans-service-selectors';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { selectRallyId } from 'scripts/selectors/user-service-selectors';
import { getLivePersonSegmentationData } from 'scripts/thunks/live-person-thunks';
import { isMR } from 'scripts/util/user/user';
import { startChatForCs, startChatForEi } from './live-person-utils';
import { setLivePersonChatStarted } from 'scripts/reducers/live-person-reducer';
import { useRouter } from '../use-router/use-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // EI and CS take different param types for startLivePerson
    startLiveperson?: (memberInfo: IMemberInfo | ISegmentationData) => void;
    // Determines EI eligibility
    getSegmentationData?: (memberInfo: IMemberInfo) => Promise<ISegmentationData>;
    // MR Chat Package
    async_msg_pkg?: IMrChatPackage;
    async_msg_claims_pkg?: IMrChatClaimsPackage;
    lpTag?: IMrChatLpTag;
    getLivePersonChatHoops?: () => Promise<IMobileHoops>;
  }
}

/**
 * Use Live Person manages Live Person Chat state in 3 easy steps:
 * 1. Load the chat script if chat is active for Line of Business.
 * 2. Determine user eligibility and return it for other hook users (Chatterbox).
 * 3. Start Chat if user is eligible.
 *
 * Live Person Chat Integration Docs:
 * https://github.optum.com/pages/async-messaging-core/async-messaging-docs/docs/portal-integration/portal-integration/
 *
 */
export function useLivePerson(): void {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const { pageName } = useRouter();
  const userProfile = useSelector(currentUser.selectProfile);
  const isEfsEligibleFF = useSelector(selectFeatureFlags).ARCADE_FEATURES_EFS_LIVE_SCRIPT_ENABELED;
  const isOptumEfsUser = isEfsEligibleFF && userProfile?.primaryPolicyNumber === '0168504';
  // isOptumGuideUser is checking for BestBuy and Qualcomm users
  const { enableOptumEfsLiveChatForBestBuy, enableOptumEfsLiveChatForQualcomm } = useFlags();

  const isOptumGuideUser =
    (enableOptumEfsLiveChatForBestBuy &&
      userProfile?.primaryPolicyNumber.includes(config.ARCADE_WEB_OPTUM_LP_BEST_BUY_POLICY)) ||
    (enableOptumEfsLiveChatForQualcomm &&
      userProfile?.primaryPolicyNumber.includes(config.ARCADE_WEB_OPTUM_LP_QUALCOMM_POLICY));
  const isOptumLpScriptLoaded = isOptumEfsUser || isOptumGuideUser;
  const isLivePersonSupportedPage = isOptumEfsUser
    ? pageName === 'Help'
    : isOptumGuideUser
    ? pageName === 'Dashboard' || pageName === 'Help'
    : pageName === 'Dashboard' ||
      pageName === 'Help' ||
      pageName === 'PremiumPayments' ||
      pageName === 'HealthResources' ||
      pageName === 'IdCards' ||
      pageName === 'Recommendations';
  const rallyId = useSelector(selectRallyId);
  const featureFlags = useSelector(selectFeatureFlags);
  const { data: benefitsData } = useSelector(selectBenefits);
  const {
    data: segmentationInfo,
    loading: segmentationInfoLoading,
    error: segmentationInfoError,
  } = useSelector(selectLivePersonSegmentationInfo);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);
  const livePersonScriptId = 'live-person-configuration';

  // Different lines of business initialize and support chat differently.
  // For MR, we are introducing a new common script approach. So we are setting the Script URL behind a FF in case of rollback
  const multiLobChatMap = {
    [LineOfBusiness.CS]: {
      isFFOn: featureFlags.ARCADE_FEATURES_LIVE_PERSON_CHAT_CS,
      scriptUrl: featureFlags.ARCADE_FEATURES_LIVE_PERSON_COMMON_SCRIPT_APPROACH_CS
        ? config.ARCADE_WEB_LIVE_PERSON_COMMON_SCRIPT_APPROACH_URL
        : config.ARCADE_WEB_LIVE_PERSON_SCRIPT_URL_CS,
      startChat: featureFlags.ARCADE_FEATURES_LIVE_PERSON_COMMON_SCRIPT_APPROACH_CS
        ? undefined
        : () => startChatForCs(userProfile),
    },
    [LineOfBusiness.EI]: {
      isFFOn: featureFlags.ARCADE_FEATURES_LIVE_PERSON_CHAT_EI,
      scriptUrl: isOptumEfsUser
        ? config.ARCADE_WEB_LIVE_PERSON_OPTUM_SCRIPT_URL_EI
        : isOptumGuideUser
        ? config.ARCADE_WEB_LP_OPTUM_SCRIPT_BESTBUY_QUALCOMM_URL
        : config.ARCADE_WEB_LIVE_PERSON_SCRIPT_URL_EI,
      startChat: () => startChatForEi(segmentationInfo?.segmentationData),
    },
    [LineOfBusiness.MR]: {
      isFFOn: featureFlags.ARCADE_FEATURES_LIVE_PERSON_CHAT_MR,
      scriptUrl: featureFlags.ARCADE_FEATURES_LIVE_PERSON_COMMON_SCRIPT_APPROACH_MR
        ? config.ARCADE_WEB_LIVE_PERSON_COMMON_SCRIPT_APPROACH_URL
        : config.ARCADE_WEB_LIVE_PERSON_SCRIPT_URL_MR,
    },
  };

  // More user info is required for MR Chat init.
  const additionalMrDataDependencies = !!benefitsData && !!rallyId;
  const dataDependenciesLoaded = !!userProfile && (!isMR(userProfile) || additionalMrDataDependencies);
  const shouldLoadLiveperson = !!userProfile && multiLobChatMap[userProfile.lineOfBusiness]?.isFFOn;

  // Step 1. Load Chat.
  useEffect(() => {
    // If window already has an LPtag, Liveperson has already been started.
    if (window.lpTag) {
      setScriptLoaded(true);
      setChatStarted(true);
      dispatch(setLivePersonChatStarted(true));
      return;
    }
    // If we have already loaded the script do not load it again.
    if (document.getElementById(livePersonScriptId)) {
      setScriptLoaded(true);
      return;
    }

    // Make sure we're supposed to load this script.
    if (!shouldLoadLiveperson || scriptLoaded || !isLivePersonSupportedPage) {
      return;
    }

    const livePersonScript = document.createElement('script');
    livePersonScript.src = multiLobChatMap[userProfile.lineOfBusiness].scriptUrl;
    livePersonScript.id = livePersonScriptId;
    livePersonScript.nonce = 'NGINX_NONCE';
    livePersonScript.defer = true;
    livePersonScript.onload = () => {
      if (!scriptLoaded) {
        setScriptLoaded(true);
      }
    };
    document.head.appendChild(livePersonScript);
  }, [shouldLoadLiveperson, isLivePersonSupportedPage]);

  // Step 2. Determine User Eligibility.
  useEffect(() => {
    // Do not make this call if we're not ready.
    if (!scriptLoaded || !dataDependenciesLoaded) {
      return;
    }

    // Do not make this call if we've already tried.
    if (segmentationInfo || segmentationInfoError || segmentationInfoLoading) {
      return;
    }

    dispatch(getLivePersonSegmentationData(userProfile, window.getSegmentationData, isOptumLpScriptLoaded));
  }, [scriptLoaded, dataDependenciesLoaded]);

  // Step 3. Start Chat.
  useEffect(() => {
    // Do not make start live person if we're not ready.
    if (!scriptLoaded || chatStarted || !dataDependenciesLoaded || !isLivePersonSupportedPage) {
      return;
    }

    // Do not start live person if user is not eligible.
    if (!segmentationInfo?.eligible) {
      return;
    }
    if (
      (!featureFlags.ARCADE_FEATURES_LIVE_PERSON_COMMON_SCRIPT_APPROACH_CS &&
        userProfile.lineOfBusiness === LineOfBusiness.CS) ||
      userProfile.lineOfBusiness === LineOfBusiness.EI
    ) {
      try {
        multiLobChatMap[userProfile.lineOfBusiness].startChat();
      } catch (e) {
        console.error('Attempt to start live person chat failed.', e);
      }
    }
    // setting chatStarted to true even if we fail to avoid retries.
    setChatStarted(true);
    dispatch(setLivePersonChatStarted(true));
  }, [segmentationInfo, isLivePersonSupportedPage, scriptLoaded]);
}
